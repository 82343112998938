body {
    font-family: "Plus Jakarta Sans";
    color: #252525;
}


.hero-maindiv{
    
  margin: auto;
width: 84%;
display: flex;
justify-content: space-between;
max-width: 1600px;
}

.text-div-hero{
    text-align: left;
    width: 60%;
    margin: auto;
    
}

.text-div-hero h1{
    text-align: left;
    color: #ED2025;
    font-size: 50px;
   font-weight:bold;
    
}
.text-div-hero h1 span{
    text-align: left;
    color: #515151;
    
}
.text-div-hero p{
    text-align: left;
    color: #515151;
    margin-top: -20px;
    width: 72%;
    
}

.img-div-hero{
    margin-right: auto;
    width: 50%;
   

}

.img-div-hero img{
    width:100%;
    display: flex;
margin-left: auto;
}

@media (max-width: 739px) {
    .hero-maindiv{
    
        margin: auto;
      width: 84%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 1600px;
      }
      
      .text-div-hero{
          text-align: left;
          width: 100%;
          margin: auto;
          order: 2;
          
      }
      
      .text-div-hero h1{
          text-align: left;
          color: #ED2025;
          font-size: 30px;
          
          
      }
      .text-div-hero h1 span{
          text-align: left;
          color: #515151;
          
      }
      .text-div-hero p{
        padding-top: 10px;
          text-align: left;
          color: #515151;
          padding-bottom: 80px;
          font-size: 15px;
          
      }
      
      .img-div-hero{
        order: 1;
        margin: auto;
        margin-top: 60px;
          margin-right: none;
          width: 100%;
         
      
      }
      
      .img-div-hero img{
          width:100%;
          display: flex;
      margin-left: none;
      margin-right: auto;
      }
      
}



@media (max-width: 430px) {
    .hero-maindiv{
    
        margin: auto;
      width: 84%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 1600px;
      }
      
      .text-div-hero{
          text-align: left;
          width: 100%;
          margin: auto;
          order: 2;
          
      }
      
      .text-div-hero h1{
          text-align: left;
          color: #ED2025;
          font-size: 24px;
          padding-left: 2px;
          
          
      }
      .text-div-hero h1 span{
          text-align: left;
          color: #515151;
          padding-left: 2px;
      }
      .text-div-hero p{
          text-align: left;
          color: #515151;
          padding-bottom: 30px;
          font-size: 13px;
          padding-left: 2px;
      }
      
      .img-div-hero{
        order: 1;
        margin: auto;
          margin-right: none;
          width: 100%;
          margin-top: 50px;
         
      
      }
      
      .img-div-hero img{
          width:100%;
          display: flex;
      margin-left: none;
      margin-right: auto;
      }
      
}
