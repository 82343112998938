body {
  font-family: "Plus Jakarta Sans";
  color: #252525;
}

.count-ranch-main-div {
  width: 84%;
  margin: auto;

  padding-top: 100px;
  padding-bottom: 100px;
  max-width: 1600px;
}

.count-section-black {
  
  border-radius: 40px;
  background-color: #252525;
  padding: 50px;
  display: flex;
  justify-content: space-between;
}

.count-section-black div {
  text-align: left;
  color: #ffff;
}
.product-count-div {
}
.count-section-black div h1 {
  font-size: 60px;
  margin: 0px;

  color: #ed2025;
  transition: transform 0.3s ease-out, 0.3s ease-out;
}

.count-section-black div h1.count-number {
  transform: scale(1);
  opacity: 1;
  transition: transform g 0.5s ease-out, 0.5s ease-out;
}

.count-section-black div h3 {
  font-size: 24px;
  margin: 0px;
}

@media (max-width: 660px) {
  .count-ranch-main-div {
    width: 84%;
    margin: auto;

    padding-top: 60px;
    padding-bottom: 30px;
  }

  .count-section-black {
    border-radius: 0px;
    background-color: #252525;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    width: 106%;
    margin-left: -10%;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .count-section-black div {
    text-align: left;
    color: #ffff;
  }

  .count-section-black div h1 {
    font-size: 40px;
    margin: 0px;
    color: #ed2025;
    transition: transform 0.3s ease-out, 0.3s ease-out;
  }

  .count-section-black div h1.count-number {
    transform: scale(1);
    opacity: 1;
    transition: transform g 0.5s ease-out, 0.5s ease-out;
  }

  .count-section-black div h3 {
    font-size: 14px;
    margin: 0px;
  }
}

@media (max-width: 450px) {
  .count-ranch-main-div {
    width: 84%;
    margin: auto;

    padding-top: 20px;
    padding-bottom: 20px;
  }

  .count-section-black {
    border-radius: 0px;
    background-color: #252525;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    width: 99%;
    margin-left: -10%;
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .count-section-black div {
    text-align: left;
    color: #ffff;
    
  }

  .count-section-black div h1 {
    font-size: 24px;
    margin: 0px;
    color: #ed2025;
    transition: transform 0.3s ease-out, 0.3s ease-out;
  }

  .count-section-black div h1.count-number {
    transform: scale(1);
    opacity: 1;
    transition: transform g 0.5s ease-out, 0.5s ease-out;
  }

  .count-section-black div h3 {
    font-size: 12px;
    margin: 0px;
  }
}

/*  Section 2 start */

.section-two-branch-div {
  text-align: left;
  margin-left: auto;

  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 2px;
}

.section-two-branch-div h1 {
  color: #ed2025;
  font-size: 40px;
}

.section-two-branch-div p {
  color: gray;
  width: 40%;
  font-size: 16px;
  margin-top: -15px;
}
@media (max-width: 990px) {
    .section-two-branch-div {
        text-align: left;
        margin-left: auto;
      
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 2px;
      }
      
      .section-two-branch-div h1 {
        color: #ed2025;
        font-size: 40px;
      }
      
      .section-two-branch-div p {
        color: #5a5757;
        width: 100%;
        font-size: 16px;
      }
}

@media (max-width: 521px) {
    .section-two-branch-div {
        text-align: center;
        margin-left: auto;
      
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 2px;
      }
      
      .section-two-branch-div h1 {
        color: #ed2025;
        font-size: 24px;
        padding-bottom: 20px;
      }
      
      .section-two-branch-div p {
        color: #5a5757;
        width: 100%;
        font-size: 12px;
        padding-left: 0px;
      }
}
/*  Section 2 End */

/*  Section 3 Start */
.historydate-div-sec-three {
  /* background-color: aqua; */
}
.date-secation-main-div {
 
 
  display: flex;

}

.date-secation-main-div div {
 
  padding: 0px;
  width: 10%;

}

.date-secation-main-div div h1 {
  color: #ed2025;
}
.date-secation-main-div div h4 {
  font-size: 14px;
}
.date-secation-main-div div div {
   height: 10px;
   width: 1px;
   margin: auto;
   background-color: #ED2025;
  }

.date-secation-main-div div p {
  font-size: 13px;
}
.date-secation-main-div > div:nth-child(1) {

}

.date-secation-main-div > div:nth-child(2) {
  margin-left: 7%;
}

.date-secation-main-div > div:nth-child(3) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(4) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(5) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(6) {
  margin-left: 7.5%;
}


.timeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0; 
    width: 100%;
    margin-left: 0%;
  }
  
  .timeline-dot {
    width: 12px;
    height: 12px;
    background-color: #ED2025; 
    border-radius: 50%;
    position: relative;
    
  }
  
  .timeline-line {
    flex: 1;
    height: 2px;
    background-color: #ED2025; 
    position: relative;
    margin-left: -6px; 
    margin-right: -6px;
    opacity: 0.3;
  }
  .timedot-2005{
    
  }
  .timeline-line:before,
  .timeline-line:after {
    content: '';
    width: 1px;
    height: 25px;
    background-color: #ED2025; /* Red color */
    position: absolute;
    margin-left: -51%;
    top: -40px; /* Align top part */
    left: 50%;
    transform: translateX(100%);
    opacity: 0.3;
  }
 


  .p-branch{
    /* background-color: #5a5757; */
    display: flex; 
    margin-top: -10px;
   
  }
  .p-branch p{
  
   font-size: 12px;
   color: gray;
   text-align: center;
  }

 
  .p-branch p:nth-child(1) {
  
  }
  
  .p-branch p:nth-child(2) {
   margin-left: 7%;
  }
  
  .p-branch p:nth-child(3) {
    margin-left: 5%;
  }
  
  .p-branch p:nth-child(4) {
    margin-left: 5.5%;
  }
  
  .p-branch p:nth-child(5) {
    margin-left: 7%;
  }
  
  .p-branch p:nth-child(6) {
    margin-left: 8.5%;
  }
  

  /* Keyframes for the line fill animation */
  @keyframes lineFill {
    0% {
      width: 0%;
      background-color: transparent;
    }
    100% {
      width: 100%;
      background-color: #ff0004; /* Red color */
    }
  }

/* Keyframes for the dot fill animation */
@keyframes dotFill {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #ff0004;
  }
}

/* Timeline container */
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 105%;
  margin-left: 4%;
  position: relative;
 
 
}

/* Line */
.timeline-line {
  flex: 1;
  height: 2px;
  background-color: transparent;
  position: relative;
  margin-left: -6px;
  margin-right: -6px;
  opacity: 0.3;
  animation: lineFill 6s ease-out forwards;
  animation-delay: 1.1s; /* Start the animation after the first dot fills */
  animation: lineFill 2s forwards;
}

/* Dots */
.timeline-dot {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: dotFill 1s ease-out forwards;
}

/* Staggering the dot fill animations */
.timeline-dot:nth-child(1) {
  animation-delay: 0s;
}

.timeline-dot:nth-child(3) {
  animation-delay: 1s;
 
}

.timeline-dot:nth-child(5) {
  animation-delay: 2s;
}

.timeline-dot:nth-child(7) {
  animation-delay: 3s;
}

.timeline-dot:nth-child(9) {
  animation-delay: 4s;
}

.timeline-dot:nth-child(11) {
  animation-delay: 5s;
}
.date-secation-main-div > div {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.date-secation-main-div > div:nth-child(2) {
  animation-delay: 0.8s;
 
 
}

.date-secation-main-div > div:nth-child(3) {
  animation-delay: 1.6s;
  
}

.date-secation-main-div > div:nth-child(4) {
  animation-delay: 2.4s;
}

.date-secation-main-div > div:nth-child(5) {
  animation-delay: 3.2s;
}

.date-secation-main-div > div:nth-child(6) {
  animation-delay: 4s;
}

.p-branch > p {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.p-branch > p:nth-child(2) {
  animation-delay: 1s;
}

.p-branch > p:nth-child(3) {
  animation-delay: 2s;
}

.p-branch > p:nth-child(4) {
  animation-delay: 3s;
}

.p-branch > p:nth-child(5) {
  animation-delay: 4s;
}

.p-branch > p:nth-child(6) {
  animation-delay: 5s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* No changes to your existing styles */

/* Start animation only when the class 'start-animation' is added */
.historydate-div-sec-three.start-animation .timeline-dot,
.historydate-div-sec-three.start-animation .timeline-line,
.historydate-div-sec-three.start-animation .date-secation-main-div > div,
.historydate-div-sec-three.start-animation .p-branch > p {
  animation-play-state: running;
}

/* Initially pause animations */
.timeline-dot,
.timeline-line,
.date-secation-main-div > div,
.p-branch > p {
  animation-play-state: paused;
}

/* Your existing animations */

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .timeline {
    padding: 10px 0;
  }

  .timeline-dot {
    width: 8px;
    height: 8px;
  }

  .timeline-line:before,
  .timeline-line:after {
    height: 10px;
  }
}


  @media (max-width: 768px) {
    .timeline {
      padding: 10px 0;
    }
  
    .timeline-dot {
      width: 8px;
      height: 8px;
    }
  
    .timeline-line:before,
    .timeline-line:after {
      height: 10px;
    }
  }
  
.phone-historydate-div-sec-three{
  display: none;
}

  @media (max-width: 720px) {
.historydate-div-sec-three {
 display: none;
}
.date-secation-main-div {
 
 
  display: flex;

}

.date-secation-main-div div {
 
  padding: 0px;
  width: 10%;

}

.date-secation-main-div div h1 {
  color: #ed2025;
}
.date-secation-main-div div h4 {
  font-size: 14px;
}
.date-secation-main-div div div {
   height: 10px;
   width: 1px;
   margin: auto;
   background-color: #ED2025;
  }

.date-secation-main-div div p {
  font-size: 13px;
}
.date-secation-main-div > div:nth-child(1) {

}

.date-secation-main-div > div:nth-child(2) {
  margin-left: 7%;
}

.date-secation-main-div > div:nth-child(3) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(4) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(5) {
  margin-left: 7.5%;
}

.date-secation-main-div > div:nth-child(6) {
  margin-left: 7.5%;
}


.timeline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0; 
    width: 105%;
    margin-left: 4%;
  }
  
  .timeline-dot {
    width: 12px;
    height: 12px;
    background-color: #ED2025; 
    border-radius: 50%;
    position: relative;
    
  }
  
  .timeline-line {
    flex: 1;
    height: 2px;
    background-color: #ED2025; 
    position: relative;
    margin-left: -6px; 
    margin-right: -6px;
    opacity: 0.3;
  }
  .timedot-2005{
    
  }
  .timeline-line:before,
  .timeline-line:after {
    content: '';
    width: 1px;
    height: 25px;
    background-color: #ED2025; /* Red color */
    position: absolute;
    margin-left: -51%;
    top: -40px; /* Align top part */
    left: 50%;
    transform: translateX(110%);
    opacity: 0.3;
  }
 


  .p-branch{
    /* background-color: #5a5757; */
    display: flex; 
    margin-top: -10px;
   
  }
  .p-branch p{
  
   font-size: 12px;
   color: gray;
   text-align: center;
  }

 
  .p-branch p:nth-child(1) {
  
  }
  
  .p-branch p:nth-child(2) {
   margin-left: 7%;
  }
  
  .p-branch p:nth-child(3) {
    margin-left: 5%;
  }
  
  .p-branch p:nth-child(4) {
    margin-left: 5.5%;
  }
  
  .p-branch p:nth-child(5) {
    margin-left: 7%;
  }
  
  .p-branch p:nth-child(6) {
    margin-left: 8.5%;
  }
  

  /* Keyframes for the line fill animation */
  @keyframes lineFill {
    0% {
      width: 0%;
      background-color: transparent;
    }
    100% {
      width: 100%;
      background-color: #ff0004; /* Red color */
    }
  }

/* Keyframes for the dot fill animation */
@keyframes dotFill {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: #ff0004;
  }
}

/* Timeline container */
.timeline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  width: 105%;
  margin-left: 4%;
  position: relative;
 
 
}

/* Line */
.timeline-line {
  flex: 1;
  height: 2px;
  background-color: transparent;
  position: relative;
  margin-left: -6px;
  margin-right: -6px;
  opacity: 0.3;
  animation: lineFill 6s ease-out forwards;
  animation-delay: 1.1s; /* Start the animation after the first dot fills */
  animation: lineFill 2s forwards;
}

/* Dots */
.timeline-dot {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: dotFill 1s ease-out forwards;
}

/* Staggering the dot fill animations */
.timeline-dot:nth-child(1) {
  animation-delay: 0s;
}

.timeline-dot:nth-child(3) {
  animation-delay: 1s;
}

.timeline-dot:nth-child(5) {
  animation-delay: 2s;
}

.timeline-dot:nth-child(7) {
  animation-delay: 3s;
}

.timeline-dot:nth-child(9) {
  animation-delay: 4s;
}

.timeline-dot:nth-child(11) {
  animation-delay: 5s;
}
.date-secation-main-div > div {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.date-secation-main-div > div:nth-child(2) {
  animation-delay: 0.8s;
}

.date-secation-main-div > div:nth-child(3) {
  animation-delay: 1.6s;
}

.date-secation-main-div > div:nth-child(4) {
  animation-delay: 2.4s;
}

.date-secation-main-div > div:nth-child(5) {
  animation-delay: 3.2s;
}

.date-secation-main-div > div:nth-child(6) {
  animation-delay: 4s;
}

.p-branch > p {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease forwards;
}

.p-branch > p:nth-child(2) {
  animation-delay: 1s;
}

.p-branch > p:nth-child(3) {
  animation-delay: 2s;
}

.p-branch > p:nth-child(4) {
  animation-delay: 3s;
}

.p-branch > p:nth-child(5) {
  animation-delay: 4s;
}

.p-branch > p:nth-child(6) {
  animation-delay: 5s;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* phone */
.phone-historydate-div-sec-three{
  display: block;

 
  padding-bottom: 40px;
}

.phone-date-secation-main-div{


 
}
.phone-date-secation-main-div div{

}

.phone-date-secation-main-div div h1{
  color: #ed2025;
 
 
}
.phone-date-secation-main-div div h4{
 font-size: 14px;
 margin-top: -20px;
}
.phone-date-secation-main-div div p{
  font-size: 12px;
  margin-top: -10px;
 }
 

.timelinephone-dot{
  width: 12px;
    height: 12px;
    background-color: #ED2025; 
    border-radius: 50%;
    margin: auto;
   
}
.timelinephone-line{
  width: 2px;
    height: 50px;
    margin: auto;
    background-color: #ED2025; 
   
  
    opacity: 0.3;
}


.phone-date-secation-main-div div h1,
.phone-date-secation-main-div div h4,
.phone-date-secation-main-div div p {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}


.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div p {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div p,
.phone-historydate-div-sec-three.start-animation .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .timelinephone-dot {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

/* First set of elements - Show after 2 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(1) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(1) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(1) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(2) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(2) .timelinephone-dot {
  transition-delay: 0s;
  opacity: 1;
  transform: translateY(0);
}

/* Second set of elements - Show after 4 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(3) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(3) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(3) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(4) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(4) .timelinephone-dot {
  transition-delay: 1s;
  opacity: 1;
  transform: translateY(0);
}

/* Third set of elements - Show after 6 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(5) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(5) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(5) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(6) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(6) .timelinephone-dot {
  transition-delay: 2s;
  opacity: 1;
  transform: translateY(0);
}

/* Fourth set of elements - Show after 8 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(7) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(7) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(7) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(8) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(8) .timelinephone-dot {
  transition-delay: 3s;
  opacity: 1;
  transform: translateY(0);
}

/* Fifth set of elements - Show after 10 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(9) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(9) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(9) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(10) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(10) .timelinephone-dot {
  transition-delay: 4s;
  opacity: 1;
  transform: translateY(0);
}
/* sixth set of elements - Show after 10 seconds */
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(11) h1,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(11) h4,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(11) p,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(11) .timelinephone-line,
.phone-historydate-div-sec-three.start-animation .phone-date-secation-main-div div:nth-child(11) .timelinephone-dot {
  transition-delay: 5s;
  opacity: 1;
  transform: translateY(0);
}
  }
