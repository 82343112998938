body {
    font-family: "Plus Jakarta Sans";
    color: #252525;
}


.maindiv-whywe{
 
 margin: auto;
    width: 84%;
    justify-content: space-between;
    display: flex;
    max-width: 1600px;
}

.cards-div-whywe{
  

}

.firstcard-section-whywe{




}

.text-firstcard-whywe{
   padding: 15px 0px 1px 28px;
    background-color: #ED2025;
    
    text-align: left;
    color: white;
    border-radius: 30px;
  margin-bottom: 40px;
  
  
}

.text-firstcard-whywe h1{
    margin-top: 0px;
font-size: 60px;
font-weight:900;


  
}
.text-firstcard-whywe p{
   
    margin-top: -30px;
font-weight: 600;
font-size: 25px;

  
}

.img-firstcard-whywe {
    
    }

.img-firstcard-whywe img{
    margin-top: 0px;
width: 350px;
height: 300px;
object-fit: cover;
object-position: center;
border-radius: 40px;
}

.text-thridcard-whywe{
    text-align: left;
    margin-top: 10px;
}

.text-thridcard-whywe button{ 
    padding: 7px 15px 7px 15px;
    font-size: 16px;
    background-color: #ed2023a1;
    border-radius: 20px;
    border: none;
    color: white;
    font-weight: 500;
    margin-left: 3px;
}

.text-thridcard-whywe h1{
    text-align: left;
    margin-top: 10px;
    font-size: 25px;
    padding-left: 3px;
}

.text-thridcard-whywe p{
    text-align: left;
    margin-top: 10px;
    font-size: 14px;
    padding-left: 3px;
    color: gray;
    margin-top: -10px;
}



.second-sectioncard-main-div{
    
   
}
.flex-div-section2{
    display: flex;
    
}

.flex-div-section2 div{
padding-left: 20px;
    
}

.cardmen-whywe{

}

.cardmen-whywe{
  background-image: url('../../images//menwhywe.jpg');
min-height: 300px;
  margin-left: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 40px;
   
}

.cardmen-whywe div{
    padding-top: 50px;
    
    text-align: left;
    
    width: 50%;
    margin-left: auto;
    margin-right: 30px;
    border-radius: 40px;
}
.cardmen-whywe div p{
    font-size: 14px;
   margin-top: -9px;
   color: gray;
}


@media (min-width: 1600px) {
    .maindiv-whywe{
       
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: left;
        color: white;
        border-radius: 30px;
      margin-bottom: 40px;
      
    }
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 57px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -30px;
    font-weight: 600;
    font-size: 25px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 25px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        
    }
    
    .flex-div-section2 div{
    padding-left: 20px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 300px;
      margin-left: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 50px;
        
        text-align: left;
        
        width: 50%;
        margin-left: auto;
        margin-right: 30px;
        border-radius: 40px;
    }
    

}


@media (max-width:1346px) {
    .maindiv-whywe{
       
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: left;
        color: white;
        border-radius: 30px;
      margin-bottom: 40px;
      
    }
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 45px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -30px;
    font-weight: 600;
    font-size: 20px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 25px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        
    }
    
    .flex-div-section2 div{
    padding-left: 20px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 300px;
      margin-left: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 50px;
        
        text-align: left;
        
        width: 50%;
        margin-left: auto;
        margin-right: 30px;
        border-radius: 40px;
    }
    

}



@media (max-width:1080px) {
    .maindiv-whywe{
      
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: left;
        color: white;
        border-radius: 30px;
      margin-bottom: 40px;
      
    }
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 45px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -30px;
    font-weight: 600;
    font-size: 20px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 100%;
    max-width: 360px;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 25px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 14px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        
    }
    
    .flex-div-section2 div{
    padding-left: 20px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 20px;
      margin-left: 30px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top:5px;
        padding-bottom: 5px;
        text-align: left;
        
        width: 50%;
        margin-left: auto;
        margin-right: 30px;
        border-radius: 40px;
    }
    

}


@media (max-width:834px) {
    .maindiv-whywe{
        
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
        
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: left;
        color: white;
        border-radius: 30px;
      margin-bottom: 40px;
      
    }
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 40px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -30px;
    font-weight: 600;
    font-size: 20px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 250px;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 20px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 12px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        
    }
    
    .flex-div-section2 div{
    padding-left: 10px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 200px;
      margin-left: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 5px;
        
        text-align: left;
        
        width: 60%;
        margin-left: auto;
        margin-right: 10px;
        border-radius: 40px;
    }
    

}
@media (max-width:660px) {
    .maindiv-whywe{
       
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
        
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: left;
        color: white;
        border-radius: 30px;
      margin-bottom: 40px;
      
    }
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 40px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -30px;
    font-weight: 600;
    font-size: 20px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 250px;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 20px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 12px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        
    }
    
    .flex-div-section2 div{
    padding-left: 10px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 230px;
      margin-left: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 5px;
        
        text-align: left;
        
        width: 60%;
        margin-left: auto;
        margin-right: 10px;
        border-radius: 40px;

    }
    .cardmen-whywe div h1{
    font-size: 23px;

    }
    .cardmen-whywe div p{
        font-size: 13px;
    
        }

}



@media (max-width:558px) {
    .maindiv-whywe{
        
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;
 
    margin-bottom: 40px;
    
    
    }
    
    .text-firstcard-whywe{
       padding: 5px 20px 5px 20px;
        background-color: #ED2025;
        
        text-align: center;
        color: white;
        border-radius: 0px;
        margin-bottom: 60px;
    margin-top: 20px;
        width: 109%;
      margin-left: -10%;
    }
    
    
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 40px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -20px;
    font-weight: 600;
    font-size: 20px;
    
      
    }
    
    .img-firstcard-whywe {
        
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 100%;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
    }
    
    .text-thridcard-whywe button{ 
        padding: 7px 15px 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
    }
    
    .text-thridcard-whywe h1{
        text-align: left;
        margin-top: 10px;
        font-size: 20px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p{
        text-align: left;
        margin-top: 10px;
        font-size: 12px;
        padding-left: 2px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        flex-direction: column;
        
    }
    
    .flex-div-section2 div{
    padding-left: 0px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 230px;
    height: 230px;
      margin-left: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 30px;
        
        text-align: left;
        
        width: 60%;
        margin-left: auto;
        margin-right: 0px;
        border-radius: 40px;

    }
    .cardmen-whywe div h1{
    font-size: 23px;

    }
    .cardmen-whywe div p{
        font-size: 13px;
    
        }
        .brnone-whywe{
            display: none;
        }
}



@media (max-width:400px) {
    .maindiv-whywe{
      
     
      width: 84%;
      margin: auto;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        
    }
    
    .cards-div-whywe{
    
    
    }
    
    .firstcard-section-whywe{
   
   width: 100%;

    
    
    
    }
    
    .text-firstcard-whywe{
        padding: 20px 18px 3px 20px;
         background-color: #ED2025;
         
         text-align: center;
         color: white;
         border-radius: 0px;
       margin-bottom: 60px;
    margin-top: 20px;
       width: 107%;
       margin-left: -10%;
     }
    .text-firstcard-whywe h1{
        margin-top: 0px;
    font-size: 40px;
    
    
      
    }
    .text-firstcard-whywe p{
       
        margin-top: -20px;
    font-weight: 600;
    font-size: 20px;

    }
    
    .img-firstcard-whywe {
      
        }
    
    .img-firstcard-whywe img{
        margin-top: 0px;
    width: 100%;
    max-width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: center;
    border-radius: 40px;
    }
    
    .text-thridcard-whywe{
        text-align: left;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }
    
    .text-thridcard-whywe h1 {
        order: 1;
        text-align: left;
        margin-top: 10px;
        font-size: 20px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe p {
        order: 2;
        text-align: left;
        margin-top: -5px;
        font-size: 12px;
        padding-left: 2px;
    }
    
    .text-thridcard-whywe button {
        order: 3;
        padding: 7px 15px;
        font-size: 16px;
        background-color: #ed2023a1;
        border-radius: 20px;
        border: none;
        color: white;
        font-weight: 500;
        margin-top: 0px;
        width: 150px;
    }
    
    
    
    .second-sectioncard-main-div{
        
       
    }
    .flex-div-section2{
        display: flex;
        flex-direction: column;
        
    }
    
    .flex-div-section2 div{
    padding-left: 0px;
        
    }
    
    .cardmen-whywe{
    
    }
    
    .cardmen-whywe{
      background-image: url('../../images//menwhywe.jpg');
    min-height: 200px;
    height: 200px;
      margin-left: 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 40px;
       
    }
    
    .cardmen-whywe div{
        padding-top: 5px;
        
        text-align: left;
        
        width: 60%;
        margin-left: auto;
        margin-right: 0px;
        border-radius: 40px;

    }
    .cardmen-whywe div h1{
    font-size: 23px;

    }
    .cardmen-whywe div p{
        font-size: 13px;
    
        }

}