body {
  font-family: "Plus Jakarta Sans";
  color: #252525;
}

.maindiv-feedback {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #EFEDEE;
  padding-bottom: 70px;
}

.feedback-h1-div{
  background-color: rgb(255, 255, 255);
  width: 100%;
  height: 130px;
 display: flex;
 margin-bottom: 30px;
 
}

.feedback-h1-div > div:nth-child(1) {
 
  background-color: #EFEDEE; 
  width: 15%;
  border-radius: 0px 40px 0px 0px;

}

.feedback-h1-div > div:nth-child(2) {
  
  background-color: #EFEDEE;
  width: 25%;
  border-radius: 40px 40px 0px 0px;
}
.secondnd-div-inner-fedback{

background-color: rgb(255, 255, 255);
width: 25%;
height: 130px;
position:absolute;
border-radius: 40px;
}
.secondnd-div-inner-fedback h1{
  font-weight: 900;
  padding-top: 25px;
}

.feedback-h1-div > div:nth-child(3) {
  background-color: #EFEDEE;
  width: 60%;
  border-radius: 40px 0px 0px 0px;
}




.firstsectionimg, .secondsectionimg {
  display: flex;
  width: 200%; 
}

.firstsectionimg img, .secondsectionimg img {
  margin: 10px;
 border-radius: 40px;
  background-color: #EFEDEE;
}


.firstsectionimg {
  animation: moveLeftToRight 10s linear infinite;
}


.secondsectionimg {
  animation: moveRightToLeft 10s linear infinite;
}

@keyframes moveLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes moveRightToLeft {
  
  100% {
    transform: translateX(0%);
  } 
  0%{
    transform: translateX(-50%);
  }
}

@media (max-width: 720px) {
  .maindiv-feedback {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #EFEDEE;
    padding-bottom: 70px;
  }
  
  .feedback-h1-div{
    background-color: rgb(255, 255, 255);
    
    width: 100%;
    height: 80px;
   display: flex;
   margin-bottom: 30px;
 
  }
  
  .feedback-h1-div > div:nth-child(1) {
   
    background-color: #EFEDEE; 
    width: 15%;
    border-radius: 0px 20px 0px 0px;
  
  }
  
  .feedback-h1-div > div:nth-child(2) {
    
    background-color: #EFEDEE;
    width: 35%;
    border-radius: 20px 20px 0px 0px;
  }
  .secondnd-div-inner-fedback{
  
  background-color: rgb(255, 255, 255);
  width: 35%;
  height: 80px;
  position:absolute;
  border-radius: 20px;
  }
  .secondnd-div-inner-fedback h1{
    font-weight: 900;
    padding-top: 15px;
    font-size: 24px;
  }
  
  .feedback-h1-div > div:nth-child(3) {
    background-color: #EFEDEE;
    width: 50%;
    border-radius: 40px 0px 0px 0px;
  }
  
  
  
  
  .firstsectionimg, .secondsectionimg {
    display: flex;
    width: 200%; 
  }
  
  .firstsectionimg img, .secondsectionimg img {
    margin: 10px;
   border-radius: 40px;
    background-color: #EFEDEE;
  }
  
  
  .firstsectionimg {
    animation: moveLeftToRight 10s linear infinite;
  }
  
  
  .secondsectionimg {
    animation: moveRightToLeft 10s linear infinite;
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  @keyframes moveRightToLeft {
    
    100% {
      transform: translateX(0%);
    } 
    0%{
      transform: translateX(-50%);
    }
  }
}

@media (max-width: 720px) {
  .maindiv-feedback {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #EFEDEE;
    padding-bottom: 10px;
  }
  
  .feedback-h1-div{
    background-color: rgb(255, 255, 255);
    
    width: 100%;
    height: 50px;
   display: flex;
   margin-bottom: 10px;
 
  }
  
  .feedback-h1-div > div:nth-child(1) {
   
    background-color: #EFEDEE; 
    width: 15%;
    border-radius: 0px 20px 0px 0px;
  
  }
  
  .feedback-h1-div > div:nth-child(2) {
    
    background-color: #EFEDEE; 
    width: 45%;
    border-radius: 20px 20px 0px 0px;
  }
  .secondnd-div-inner-fedback{
  
  background-color: rgb(255, 255, 255);
  width: 45%;
  height: 50px;
  position:absolute;
  border-radius: 20px;
  }
  .secondnd-div-inner-fedback h1{
    font-weight: 900;
    padding-top: 0px;
    font-size: 18px;
  }
  
  .feedback-h1-div > div:nth-child(3) {
    background-color: #EFEDEE;
    width: 40%;
    border-radius: 20px 0px 0px 0px;
  }
  
  
  
  
  .firstsectionimg, .secondsectionimg {
    display: flex;
    width: 200%; 
  }
  
  .firstsectionimg img, .secondsectionimg img {
    width: 300px;
    margin: 10px;
   border-radius: 40px;
    background-color: #EFEDEE;
  }
  
  
  .firstsectionimg {
    animation: moveLeftToRight 10s linear infinite;
  }
  
  
  .secondsectionimg {
    animation: moveRightToLeft 10s linear infinite;
  }
  
  @keyframes moveLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  @keyframes moveRightToLeft {
    
    100% {
      transform: translateX(0%);
    } 
    0%{
      transform: translateX(-50%);
    }
  }
}