body {
  font-family: "Plus Jakarta Sans";
  color: #252525;
}

.nav-bar-div {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 84%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
}

.logo-img-nav {
  height: 80px;
}

.nav-btn-div {
  display: flex;
  align-items: center;
}

.locationimg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.locationbtn {
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px;
  background-color: white;
  padding: 10px 15px 10px 10px;
  cursor: pointer;
  border: solid;
  border-width: 1px;
  border-color: gray;
}

.button-menu-nav {
  border: none;
  border-radius: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  background-color: rgb(240, 240, 240);
  outline: none;
  box-shadow: none;
}

.MuiSvgIcon-root {
  width: 14px;
  height: 14px;
}

.button-menu-nav:hover {
  background-color: #ED2025;
  color: white;
}

@keyframes topToBottom {
  from {
      transform: translateY(-100%);
  }
  to {
      transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
      transform: translateX(-100%);
  }
  to {
      transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-100%);
  }
}

.menu-items-btn-div {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  z-index: 3000;
  padding-bottom: 20px;
  width: 100%;
  box-shadow: 2px 2px 6px rgb(201, 201, 201);
  animation-duration: 0.3s;
}

.menu-items-btn-div.visible {
  display: block;
  animation-name: topToBottom;
}

.menu-items-btn-div.hidden {
  animation-name: slideOut;
  animation-fill-mode: forwards;
}

.menu-items-btn-div button {
  display: block;
  width: 100%;
  padding: 10px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: bold;
}

.menu-items-btn-div button:hover {
  background: #ED2025;
  color: white;
}

.menu-items-btn-div div button {
  background-color: transparent;
  width: 10%;
  margin-right: 30px;
  margin-top: 30px;
  font-size: 16px;
  margin-left: auto;
  color: rgb(143, 143, 143);
}

.menu-items-btn-div div button:hover {
  background-color: transparent;
  width: 10%;
  margin-right: 30px;
  margin-top: 30px;
  font-size: 16px;
  margin-left: auto;
  color: rgb(0, 0, 0);
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: rgb(143, 143, 143);
}

.close-btn:hover {
  color: #ED2025;
}

@media (min-width: 1600px) {
  .nav-bar-div {
      margin-top: 40px;
      width: 84%;
      margin: auto;
      display: flex;
      justify-content: space-between;
  }
  
  .logo-img-nav {
      height: 50px;
  }
  
  .nav-btn-div {
      margin-bottom: auto;
      display: flex;
      align-items: center;
  }
  
  .locationimg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
  }
  
  .locationbtn {
      margin-bottom: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background-color: white;
      padding: 10px 20px;
      cursor: pointer;
      border: solid;
      border-width: 1px;
      border-color: gray;
  }
  
  .button-menu-nav {
      border: none;
      border-radius: 100%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      background-color: rgb(240, 240, 240);
      outline: none;
      box-shadow: none;
  }
  
  .MuiSvgIcon-root {
      width: 14px;
      height: 14px;
  }
}

@media (max-width: 720px) {
  .nav-bar-div {
      padding-top: 40px;
      width: 84%;
      margin: auto;
      margin-bottom: 50px;
      display: flex;
      justify-content: space-between;
      max-width: 1600px;
  }
  
  .logo-img-nav {
      height: 50px;
  }
  
  .nav-btn-div {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .locationbtn {
      width: 125px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 30px;
      background-color: white;
      padding: 10px 20px;
      cursor: pointer;
      border: solid;
      border-width: 1px;
      border-color: gray;
  }
  
  .button-menu-nav {
      border: none;
      border-radius: 100%;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      background-color: rgb(240, 240, 240);
      outline: none;
      box-shadow: none;
  }
  
  .MuiSvgIcon-root {
      width: 14px;
      height: 14px;
  }
  
  .button-menu-nav:hover {
      background-color: #ED2025;
      color: white;
  }
}
