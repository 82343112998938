body {
    font-family: "Plus Jakarta Sans";
    color: #252525;
}
.main-div-fotter{
    padding-top: 50px;
    width: 100%;
    background-color: #ED2025;
    padding-bottom: 20px;
    max-width: 1600px;
    margin: auto;
}

.first-section-fotter{
    background-color: #ED2025;
    width: 84%;
    margin: auto;
    justify-content: space-between;
    display: flex;
}
.first-div-fotter{
    width: 30%;
    text-align: left;
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.first-div-fotter hr{
background-color: rgb(255, 255, 255);
height: 1px;
border: none;
margin-top: 50px;
margin-bottom: 30px;
}
.head-question-fotter{
    font-size: 16px;
}
.two-div-fotter{
    padding-top: 50px;
  
    width: 30%;
    text-align: left;
   
    color: rgb(255, 255, 255);
}
.team-div-fotter{
    width: 70%;
    display: flex;
    justify-content: space-between;
}
.second-team-div-fotter div {

}
.second-team-div-fotter p{
    margin-top: 65px;
}
.second-team-div-fotter div input{
   outline: none;
   height: 35px;
   border: none;
   width: 60%;
   border: solid;
   border-width: 1px;
   border-radius: 5px;
   border-color: white;
   background-color: #ED2025;
   padding-left: 10px;
}
.second-team-div-fotter div input::placeholder::placeholder{
    color: white;
 }
.second-team-div-fotter div button{
    border: none;
    height: 39px;
    width: 30%;
    margin-left: -2%;
    border-radius: 5px;
 }

 .icon-div-fotter{
 
 }
 /* Add this to your CSS file */
.icon-div-fotter {
    display: flex;
    justify-content: center; /* Center the icon if needed */
  }
  
  .icon-div-fotter .icon {
   margin: 5px;
    fill: white;

  }
.fotter-last-p{
    font-size: 14px;
 color: white;
}  
  


@media (max-width: 720px) {
    .main-div-fotter{
        padding-top: 50px;
        width: 100%;
        background-color: #ED2025;
        padding-bottom: 20px;
        max-width: 1600px;
        margin: auto;
    }
    
    .first-section-fotter{
        background-color: #ED2025;
        width: 84%;
        margin: auto;
        justify-content: space-around;
        display: flex;
        flex-direction: column;
    }
    .first-div-fotter{
        width: 100%;
        text-align: left;
        font-size: 13px;
        color: rgb(255, 255, 255);
    }
    
    .first-div-fotter hr{
    background-color: rgb(255, 255, 255);
    height: 1px;
    border: none;
    margin-top: 50px;
    margin-bottom: 30px;
    }
    .head-question-fotter{
        font-size: 16px;
    }
    .two-div-fotter{
        padding-top: 30px;
      
        width: 100%;
        text-align: left;
       
        color: rgb(255, 255, 255);
    }
    .team-div-fotter{
        width: 70%;
        display: flex;
        justify-content: space-between;
    }
    .second-team-div-fotter div {
    
    }
    .second-team-div-fotter p{
        margin-top: 15px;
    }
    .second-team-div-fotter div input{
       outline: none;
       height: 35px;
       border: none;
       width: 60%;
       border: solid;
       border-width: 1px;
       border-radius: 5px;
       border-color: white;
       background-color: #ED2025;
       padding-left: 10px;
    }
    .second-team-div-fotter div button{
        border: none;
        height: 39px;
        width: 30%;
        margin-left: -2%;
        border-radius: 5px;
     }
    
     .icon-div-fotter{
     
     }
     /* Add this to your CSS file */
    .icon-div-fotter {
        display: flex;
        justify-content: center; /* Center the icon if needed */
      }
      
      .icon-div-fotter .icon {
       margin: 5px;
        fill: white;
        margin-top: 40px;
    
      }
    .fotter-last-p{
        width: 84%;
        margin: auto;
        font-size: 14px;
     color: white;
    }  
      
}