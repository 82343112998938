body {
    font-family: "Plus Jakarta Sans", sans-serif;
    color: #252525;
}

.main-our-promis-div {
    width: 84%;
    margin: auto;
    max-width: 1600px;
}

.our-promis-first-card {
    margin-bottom: 50px;
    width: 100%;
    border-radius: 40px;
    position: relative;
    display: flex;
}

.our-promis-first-card::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 54.5%; /* Adjust width to control the border area */
    border-right: 1px solid rgb(237, 32, 37, 0.5); /* Border color and style */
    border-top: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on top */
    border-bottom: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on bottom */
    border-radius: 0 40px 40px 0; /* Match the parent border-radius on right */
    box-sizing: border-box;
}

.img-div-ourpromis-card {
    width: 50%;
    background-image: url('../../images/threepersonwhywe.png');
    background-position: center;
    background-size: cover;
    border-radius: 40px;
}

.content-card-our-promis {
    width: 40%;
    padding: 70px;
    text-align: left;
}

.content-card-our-promis h1 {
    color: #ED2025;
    font-size: 40px;
}

.our-promis-second-card {
    margin-bottom: 50px;
    width: 100%;
    border-radius: 40px;
    position: relative;
    display: flex;
   
}

.our-promis-second-card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0; /* Changed from right to left */
    bottom: 0;
    width: 54.5%; /* Adjust width to control the border area */
    border-left: 1px solid rgb(237, 32, 37, 0.5); /* Border color and style */
    border-top: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on top */
    border-bottom: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on bottom */
    border-radius: 40px 0 0 40px; /* Match the parent border-radius on left */
    box-sizing: border-box;
}

.second-img-div-ourpromis-card {
    width: 50%;
    background-image: url('../../images/carier.png');
    background-position: center;
    background-size: cover;
    border-radius: 40px;
    min-height: 300px;
}

.content-second-card-our-promis {
    width: 40%;
    padding-top: 30px;
    padding-bottom: 50px;
    padding-right: 70px;
    padding-left: 70px;
    text-align: left;
}

.content-second-card-our-promis h1 {
    color: #ED2025;
    font-size: 40px;
}

@media (max-width: 720px) {
    .main-our-promis-div {
        width: 84%;
        margin: auto;
        max-width: 1600px;
    }
    
    .our-promis-first-card {
        margin-bottom: 50px;
        width: 100%;
        border-radius: 40px;
        position: relative;
        display: flex;
    }
    
    .our-promis-first-card::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 74.5%; /* Adjust width to control the border area */
        border-right: 1px solid rgb(237, 32, 37, 0.5); /* Border color and style */
        border-top: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on top */
        border-bottom: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on bottom */
        border-radius: 0 40px 40px 0; /* Match the parent border-radius on right */
        box-sizing: border-box;
    }
    
    .img-div-ourpromis-card {
        width: 50%;
        background-image: url('../../images/threepersonwhywe.png');
        background-position: center;
        background-size: cover;
        border-radius: 40px;
    }
    
    .content-card-our-promis {
        width: 60%;
        padding:    10px;
        text-align: left;
    }
    
    .content-card-our-promis h1 {
        color: #ED2025;
        font-size: 20px;
    }
    .content-card-our-promis h4 {
        margin-top: -10px;
        font-size: 14px;
    }
    .content-card-our-promis p {
      margin-top: -10px;
        font-size: 12px;
    }
    
    .our-promis-second-card {
        margin-bottom: 50px;
        width: 100%;
        border-radius: 40px;
        position: relative;
        display: flex;
       
    }
    
    .our-promis-second-card::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0; /* Changed from right to left */
        bottom: 0;
        width: 74.5%; /* Adjust width to control the border area */
        border-left: 1px solid rgb(237, 32, 37, 0.5); /* Border color and style */
        border-top: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on top */
        border-bottom: 1px solid rgb(237, 32, 37, 0.5); /* Optional: border on bottom */
        border-radius: 40px 0 0 40px; /* Match the parent border-radius on left */
        box-sizing: border-box;
    }
    
    .second-img-div-ourpromis-card {
        width: 50%;
        background-image: url('../../images/carier.png');
        background-position: center;
        background-size: cover;
        border-radius: 40px;
        min-height: 100px;
        
    }
    
    .content-second-card-our-promis {
        width: 60%;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        padding-left: 10px;
        text-align: left;
    }
    
   
    .content-second-card-our-promis h1 {
        color: #ED2025;
        font-size: 20px;
    }
    .content-second-card-our-promis h4 {
        margin-top: -10px;
        font-size: 14px;
    }
    .content-second-card-our-promis p {
      margin-top: -10px;
        font-size: 12px;
    }
}


